
import { defineComponent } from 'vue'
import Apple from '@/assets/svg/apple.svg?inline'
import Android from '@/assets/svg/android.svg?inline'

export default defineComponent({
  name: 'InstallOurApp',
  components: {
    Apple,
    Android
  }
})
